import { defineStore } from 'pinia'
import {useCookieStore} from "~/store/cookie";

export const useRoadmapStore = defineStore({
  id: 'roadmap-store',
  state: () => {
    return {
      roadmapPage: null,
      fetchError: false,
      currentRoadmapPage: 1,
      roadmapGroups: {},
      voteError: false
    }
  },

  actions: {
    async fetchAllRoadMapItems(locale, config) {
      this.fetchError = false;
      while (this.roadmapPage === null ||
        (this.roadmapPage !== undefined && this.roadmapPage.total_pages > this.currentRoadmapPage)) {
        await this.fetchRoadmap(this.currentRoadmapPage, locale, config);
        if (this.fetchError) {
          break;
        }

        if (this.roadmapPage) {
          for (let item of this.roadmapPage.results) {
            if (! this.roadmapGroups[item.status]) {
              this.roadmapGroups[item.status] = [];
            }
            this.roadmapGroups[item.status].push(item);
          }
        }

        this.currentRoadmapPage++;
      }
    },
    async fetchRoadmap(page, locale, config) {
      const cookieStore = useCookieStore();
      if (! locale || locale.indexOf('en') === 0) {
        locale = 'en';
      } // until we have roadmap items in other languages

      let params = {
          'page': page,
          'locale': locale
      }

      let headers = {}

      if (cookieStore.token) {
        headers = {
          'Authorization': 'Token ' + cookieStore.token,
        }
      }

      const resp = await $fetch(config.public.externalApiBase + `/api/roadmap/`, {
        method: 'GET',
        params: params,
        headers: headers,
      }).catch((error) => {
        this.fetchError = true;
        console.log(error);
      });
      if (! this.fetchError) {
        this.roadmapPage = resp;
      }
    },
    async voteRoadmapItem(item, value, config) {
      this.voteError = false;
      const cookieStore = useCookieStore();
      const resp = await $fetch(config.public.externalApiBase + `/api/roadmap/vote/`, {
        method: 'POST',
        headers: {
          'Authorization': 'Token ' + cookieStore.token,
        },
        params: {
          'roadmap_item_id': item.id,
          'vote': value,
        }
      }).catch((error) => {
        console.log(error);
        this.voteError = true;
      });

    }
  }
});