<template>
  <slot name="header">
      <Header bg-color="bg-slate-900"/>
  </slot>
  <div class="max-w-7xl mx-auto px-4 md:px-6 mt-mobile-navbar lg:mt-navbar py-8 md:py-12 lg:py-20">
    <h1 class="font-bold text-center text-brand-500 mb-2 font-poppins">{{ t('roadmap.heading') }}</h1>
    <h2 class="text-dark-900 text-h1s md:text-h1m lg:text-h1l font-bold mb-8 md:mb-10 text-center font-poppins">{{ t('roadmap.desc') }}</h2>

    <div class="lg:grid lg:grid-cols-3 gap-6 xl:gap-10">
      <LazyRoadmapGroup bg-color="bg-blue-50"
        :items="roadmapGroups['planned']" status="planned" :title="t('roadmap.planned')" />

      <LazyRoadmapGroup bg-color="bg-slate-50"
        :items="roadmapGroups['in_progress']" status="in_progress" :title="t('roadmap.in.progress')" />

      <LazyRoadmapGroup bg-color="bg-green-50"
        :items="roadmapGroups['complete']" status="complete" :title="t('roadmap.complete')" />
    </div>

    <!-- Notice Section -->
    <div class="bg-brand-500 text-white rounded-lg mb-6 p-6 mt-6 flex flex-col md:flex-row md:justify-between items-start md:gap-8 lg:gap-10">
      <div>
        <h2 class="font-bold mb-2">{{ t('roadmap.request.feature.title') }}</h2>
        <p class="text-sm">{{ t('roadmap.request.feature.desc') }}</p>
      </div>
      <button @click="openFeedbackForm" class="font-bold text-sm bg-white text-dark-900 rounded-m py-2 px-4 flex items-center flex-shrink-0">
        {{ t('roadmap.request.feature.button') }}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="w-4 h-auto">
          <mask id="mask0_85_4835" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
            <rect width="16" height="16" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_85_4835)">
            <path d="M8.91103 8.00016L5.33325 4.42239L6.42214 3.3335L11.0888 8.00016L6.42214 12.6668L5.33325 11.5779L8.91103 8.00016Z" fill="black"/>
          </g>
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup>
import { useRoadmapStore} from "~/store/roadmap";
import {useI18n} from "vue-i18n";
import {storeToRefs} from "pinia";
import {useUtilsStore} from "~/store/utils";
const config = useRuntimeConfig();
const {t, locale} = useI18n({
  useScope: 'local'
});
const roadmapStore = useRoadmapStore();

const {roadmapGroups, fetchError } = storeToRefs(roadmapStore);
const {feedbackFormDialog} = storeToRefs(useUtilsStore());

definePageMeta({
  layout: "defaultgrey",
});

await roadmapStore.fetchAllRoadMapItems(locale.value, config);
let localeExtra = locale.value === 'en-us' ? ' (USA)' : '';

useHead({
    title: t('roadmap.title') + localeExtra,
    meta: [
        { key: 'og-type', property: 'og:type', content: 'website' },
        { key: 'description', name: 'description', content: t('roadmap.meta.description') + localeExtra},
        { key: 'og-title', property: 'og:title', content: t('roadmap.title') },
        { key: 'og-desc', property: 'og:description', content: t('roadmap.meta.description') },
        { key: 'og-image', property: 'og:image', content: 'https://store.soundmadeseen.com/sms-og-image.jpg' },
        { key: 'og-url', property: 'og:url', content: 'https://soundmadeseen.com' },
        { key: 'og-site-name', property: 'og:site_name', content: 'SoundMadeSeen' },
        { key: 'twitter-card', name: 'twitter:card', content: 'summary_large_image' },
        { key: 'twitter-title', name: 'twitter:title', content: t('roadmap.title') },
        { key: 'twitter-desc', name: 'twitter:description', content: t('roadmap.meta.description') },
        { key: 'twitter-image', name: 'twitter:image', content: 'https://store.soundmadeseen.com/sms-og-image.jpg' },
        { key: 'twitter-site', name: 'twitter:site', content: '@soundmadeseen' },
        { key: 'twitter-creator', name: 'twitter:creator', content: '@soundmadeseen' },
    ]
});

const openFeedbackForm = () => {
  feedbackFormDialog.value = true;
}

</script>

<i18n lang="json">
{
  "en": {
    "roadmap.title": "Product Roadmap - SoundMadeSeen",
    "roadmap.heading": "Roadmap",
    "roadmap.desc": "What we're working on",
    "roadmap.meta.description": "SoundMadeSeen product roadmap - see what we're working on right now, vote for features and suggest new features",
    "roadmap.complete": "Complete",
    "roadmap.planned": "Planned",
    "roadmap.in.progress": "In progress",
    "roadmap.request.feature.title": "Request a feature",
    "roadmap.request.feature.desc": "Have an idea for a feature you'd like to see in SoundMadeSeen?  Let us know!",
    "roadmap.request.feature.button": "Request a feature"
  },
  "de": {
    "roadmap.title": "Produktfahrplan - SoundMadeSeen",
    "roadmap.heading": "Fahrplan",
    "roadmap.desc": "Woran wir arbeiten",
    "roadmap.meta.description": "Produktfahrplan von SoundMadeSeen - sehen Sie, woran wir gerade arbeiten, stimmen Sie über Funktionen ab und schlagen Sie neue Funktionen vor",
    "roadmap.complete": "Abgeschlossen",
    "roadmap.planned": "Geplant",
    "roadmap.in.progress": "In Bearbeitung",
    "roadmap.request.feature.title": "Eine Funktion anfordern",
    "roadmap.request.feature.desc": "Haben Sie eine Idee für eine Funktion, die Sie gerne bei SoundMadeSeen sehen würden? Lassen Sie es uns wissen!",
    "roadmap.request.feature.button": "Funktion anfordern"
  },
  "fr": {
    "roadmap.title": "Feuille de route du produit - SoundMadeSeen",
    "roadmap.heading": "Feuille de route",
    "roadmap.desc": "Sur quoi nous travaillons",
    "roadmap.meta.description": "Feuille de route du produit SoundMadeSeen - voyez sur quoi nous travaillons actuellement, votez pour les fonctionnalités et proposez de nouvelles fonctionnalités",
    "roadmap.complete": "Complété",
    "roadmap.planned": "Prévu",
    "roadmap.in.progress": "En cours",
    "roadmap.request.feature.title": "Demander une fonctionnalité",
    "roadmap.request.feature.desc": "Vous avez une idée de fonctionnalité que vous aimeriez voir dans SoundMadeSeen? Faites-le nous savoir !",
    "roadmap.request.feature.button": "Demander une fonctionnalité"
  },
  "es": {
    "roadmap.title": "Hoja de Ruta del Producto - SoundMadeSeen",
    "roadmap.heading": "Hoja de Ruta",
    "roadmap.desc": "En qué estamos trabajando",
    "roadmap.meta.description": "Hoja de ruta del producto SoundMadeSeen - vea en qué estamos trabajando ahora, vote por las características y sugiera nuevas características",
    "roadmap.complete": "Completo",
    "roadmap.planned": "Planificado",
    "roadmap.in.progress": "En progreso",
    "roadmap.request.feature.title": "Solicitar una característica",
    "roadmap.request.feature.desc": "¿Tiene una idea para una característica que le gustaría ver en SoundMadeSeen? ¡Háganoslo saber!",
    "roadmap.request.feature.button": "Solicitar una característica"
  },
  "ja": {
    "roadmap.title": "製品ロードマップ - SoundMadeSeen",
    "roadmap.heading": "ロードマップ",
    "roadmap.desc": "現在取り組んでいること",
    "roadmap.meta.description": "SoundMadeSeenの製品ロードマップ - 現在取り組んでいる内容、機能の投票、新機能の提案をご覧ください",
    "roadmap.complete": "完了",
    "roadmap.planned": "計画中",
    "roadmap.in.progress": "進行中",
    "roadmap.request.feature.title": "機能リクエスト",
    "roadmap.request.feature.desc": "SoundMadeSeenに追加してほしい機能のアイデアがありますか？お知らせください！",
    "roadmap.request.feature.button": "機能をリクエスト"
  }
}
</i18n>
<style scoped>

</style>